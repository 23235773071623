<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.full_name')">
                <el-select :maxlength="20" v-model="formInline.operatorIds" placeholder="请选择">
                  <el-option label="请选择" value=""></el-option>
                  <el-option
                    v-for="item in oprList"
                    :key="item.bossManagerId"
                    :label="item.bossManagerName"
                    :value="item.bossManagerId"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="统计周期">
                <el-date-picker
                  v-model="time"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="timeChange"
                ></el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <el-button type="info" icon="el-icon-upload2" @click="exportFile" :loading="loading"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center"></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat, exportExcelNew } from "@/common/js/public";
export default {
  components: {},
  data() {
    let today = dateFormat(new Date(), "yyyy-MM-dd");
    let yesterday = dateFormat(new Date(new Date().getTime() - 86400000 * 31), "yyyy-MM-dd");
    return {
      time: [yesterday, today],
      loading: false,
      formInline: {
        operatorIds: "",
        startDate: yesterday,
        endDate: today,
      },
      oprList: [],
      tableData: [],
      tableCols: [
        {
          prop: "operatorName",
          label: this.$t("list.full_name"),
          width: "",
        },
        {
          prop: "dataDate",
          label: this.$t("list.date"),
          width: "",
        },
        {
          prop: "complaintNum",
          label: "处理总量",
          width: "",
        },
      ],
    };
  },
  methods: {
    exportFile() {
      if (!this.formInline.startDate || !this.formInline.endDate) {
        this.$alert("请选择时间");
        return;
      }
      exportExcelNew("/acb/2.0/bigDataCount/operatorcomplaintExport", {
        ...this.formInline,
      });
    },
    timeChange() {
      if (this.time) {
        this.formInline.startDate = dateFormat(this.time[0], "yyyy-MM-dd");
        this.formInline.endDate = dateFormat(this.time[1], "yyyy-MM-dd");
      } else {
        this.formInline.startDate = "";
        this.formInline.endDate = "";
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    getOprList() {
      this.$axios
        // .get("/acb/2.0/bossManager/getManagerListByAreaId")
        .get("/acb/2.0/bossManager/listVO", {
          data: {
            page: 1,
            size: 10000,
          },
        })
        .then((res) => {
          this.oprList = res.value.list;
        });
    },
    searchData() {
      if (!this.formInline.startDate || !this.formInline.endDate) {
        this.$alert("请选择时间");
        return;
      }
      this.loading = true;
      this.$axios
        .get("/acb/2.0/bigDataCount/operatorcomplaint", {
          data: {
            ...this.formInline,
          },
        })
        .then((res) => {
          this.loading = false;
          this.tableData = res.value;
        });
    },
  },
  created() {
    this.getOprList();
    this.searchData();
  },
};
</script>

<style lang="stylus" scoped></style>
